body,
.root {
  width: 100%;
  margin: 0;
}

a {
  color: white;
}

.app {
  background-attachment: fixed;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}
.app-sections {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
